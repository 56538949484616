/* eslint-disable */

import {
  BASE_PATH,
  BaseAPI,
  FetchParams,
  Middleware,
  RequestContext,
  ResponseContext
} from 'digitale-doerfer-api/runtime';
import { ClientExceptionEntity } from 'digitale-doerfer-api/models';
import { BACKEND_URL } from './Settings';

const APP_VARIANT_ID_HEADER = 'appVariantIdentifier';
const APP_VARIANT_ID = 'de.fhg.iese.dd.ext-post-view';

class DigitaleDoerferMiddleware implements Middleware {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const { init, url } = context;
    const headers = new Headers(init.headers);
    headers.set(APP_VARIANT_ID_HEADER, APP_VARIANT_ID);
    init.headers = headers;
    const newUrl = BACKEND_URL + url.substr(BASE_PATH.length + 1);
    return {
      init,
      url: newUrl
    };
  }

  async post(context: ResponseContext): Promise<Response | void> {
    if (context.response.status >= 400) {
      // Server returned with HTTP error code, so response is ClientException.
      // If for certain cases, this is not true, add handling here.

      // TODO REMOVE NEXT LINE
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const errorResponse: ClientExceptionEntity = await context.response.json();

      // Note that here it is assumed that the underlying, autogenerated code of runtime.ts
      // does not "swallow" the exception.
      // See https://erbenschell.iese.fraunhofer.de/DigitaleDoerfer/Web/platform-admin-ui/-/tags/advanced-error-handling
      // for a more advanced version.
      // throw new ClientExceptionError(errorResponse);
    }
  }
}

class DigitaleDoerferAPIFactory {
  private static middleWare: Middleware;

  public static createMiddleware(): void {
    DigitaleDoerferAPIFactory.middleWare = new DigitaleDoerferMiddleware();
  }

  public static createDigitaleDoerferAPI<T extends BaseAPI>(api: T): T {
    if (!DigitaleDoerferAPIFactory.middleWare) {
      throw new Error('Middleware not initialised!');
    }
    return api.withMiddleware(DigitaleDoerferAPIFactory.middleWare);
  }
}

export default DigitaleDoerferAPIFactory;
