/* eslint-disable */

import React from 'react';
import clsx from 'clsx';

interface Props {
  page: number;
  totalPages: number;
  handleChangePage: (newPage: number) => void;
}
const NavigatorView = (props: Props): JSX.Element => {
  const { page, totalPages, handleChangePage } = props;
  const currentPage = page + 1;
  const pageInfo = `${currentPage} von ${totalPages}`;
  return (
    <>
      {totalPages !== 0 ? (
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center pagination-lg text-dark">
            <li
              className={
                page === 0 ? clsx('page-item', 'disabled-button') : 'page-item'
              }
              onClick={(): void => handleChangePage(page - 1)}
            >
              <div className="btn page-link" aria-label="Previous">
                <span className="text-dark font-weight-bold" aria-hidden="true">
                  &laquo;
                </span>
              </div>
            </li>
            <li className="page-item">
              <span className="page-link page-count text-dark font-weight-bold">
                {pageInfo}
              </span>
            </li>
            <li
              className={
                currentPage === totalPages
                  ? clsx('page-item', 'disabled-button')
                  : 'page-item'
              }
              onClick={(): void => handleChangePage(page + 1)}
            >
              <div className="btn page-link" aria-label="Next">
                <span className="text-dark font-weight-bold" aria-hidden="true">
                  &raquo;
                </span>
              </div>
            </li>
          </ul>
        </nav>
      ) : (
        ''
      )}
    </>
  );
};

export default NavigatorView;
