import { ADDITIONAL_TEST_GEO_AREA_ID } from './shared/utils/Settings';
import { Logo } from './models/Logo';
import logoSMRSachsen from './assets/logo_SMR_Sachsen.png';
import logoSimuplus from './assets/logo_simulplus.png';
import logoLommatzscher from './assets/logo_lommatzscher.png';
import logoFraunhoferIese from './assets/logo_fraunhofer_iese.png';

const lpGeoAreas = [
  { id: 'ab0c2db7-bc05-443b-ab22-3fef386fc4bf', name: 'Diera-Zehren' },
  { id: '8e743f42-d715-4cb9-a11b-83e7847b70dd', name: 'Käbschütztal' },
  { id: 'c8c35696-c52f-4df2-bb93-6386c054197a', name: 'Hirschstein' },
  { id: 'b4510da0-8ed5-4914-afe1-d848ad40e240', name: 'Klipphausen' },
  { id: 'cf69bf6d-7eaa-41e4-8fca-9c28d7c74f0a', name: 'Lommatzsch' },
  { id: '7ab02ca3-83e2-49e6-b4ad-928f988721d5', name: 'Nossen' },
  { id: '375cb611-917d-44ec-b092-f63c7e7932f1', name: 'Ostrau' },
  { id: '3e0a7fe9-e78a-4850-a4f5-2f90f29f0083', name: 'Stauchitz' },
  { id: 'cc1827b4-4159-4025-a700-64ae9f37561e', name: 'Zschaitz-Ottewig' },
  { id: '56e66c00-d1e0-48b1-8e5c-871e9f63b43d', name: 'Meißen' }
];

const geoAreasListToExport = [];

if (ADDITIONAL_TEST_GEO_AREA_ID) {
  geoAreasListToExport.push({
    id: ADDITIONAL_TEST_GEO_AREA_ID,
    name: 'Testdorf'
  });
}

geoAreasListToExport.push(...lpGeoAreas);

export const geoAreasList = geoAreasListToExport;

export const externalPostList = [
  { name: 'Neuigkeiten', postType: 'NEWS_ITEM' },
  { name: 'Veranstaltungen', postType: 'HAPPENING' }
];

export const logos: Logo[] = [
  {
    webPageURL: 'https://www.iese.fraunhofer.de',
    logoSource: logoFraunhoferIese
  },
  {
    webPageURL: 'https://www.lommatzscher-pflege.de/',
    logoSource: logoLommatzscher
  },
  {
    webPageURL: 'https://www.simulplus.sachsen.de/',
    logoSource: logoSimuplus
  },
  {
    webPageURL: 'https://www.smr.sachsen.de/',
    logoSource: logoSMRSachsen
  }
];
