import React, { useEffect, useState } from 'react';
import { ClientPost, PageOfClientPost } from 'digitale-doerfer-api/models';
import {
  GetAllExternalPostsByGeoAreasUsingGETSortByEnum,
  GetAllExternalPostsByGeoAreasUsingGETPostTypesEnum as PostTypeEnum
} from 'digitale-doerfer-api/apis';
import HeaderView from './views/Header.view';
import FooterView from './views/Footer.view';
import { GeoArea } from './models/GeoArea';
import { Pagination } from './models/Pagination';
import { grapevinePostApi } from './ServiceFactory';
import { ExternalPostType } from './models/ExternalPost';
import ExternalPostsView from './views/ExternalPosts.view';
import { externalPostList, geoAreasList, logos } from './config';
import { REACT_APP_TITLE } from './shared/utils/Settings';

function ExternalPostsContainer(): JSX.Element {
  const [geoAreas, setGeoAreas] = useState<GeoArea[]>([]);
  const [externalPosts, setExternalPosts] = useState<ExternalPostType[]>([]);

  const initiapPaginationParams = {
    page: 0,
    rowsPerPage: 6,
    totalElements: 0,
    totalPages: 0
  };
  const [pagination, setPagination] = useState<Pagination>(
    initiapPaginationParams
  );
  const [pageIndex, setPageIndex] = useState<number>(0);

  const [clientPosts, setClientPosts] = useState<ClientPost[]>([]);
  const [selectedGeoAreas, setSelectedGeoAreas] = useState<GeoArea[]>([]);
  const [selectedPostTypes, setSelectedPostTypes] = useState<PostTypeEnum[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const getGeoAreaIds = (geoAreaList: GeoArea[]): string[] => {
    return geoAreaList.map(geo => geo.id);
  };

  const updateAppTitle = (title: string): void => {
    document.title = title;
  };

  const getProccesedExteralPosts = (): ExternalPostType[] => {
    return externalPostList.map(ep => {
      switch (ep.postType) {
        case PostTypeEnum.HAPPENING:
          return { name: ep.name, postType: PostTypeEnum.HAPPENING };
        case PostTypeEnum.NEWSITEM:
          return { name: ep.name, postType: PostTypeEnum.NEWSITEM };
        // Next cases are for future updates
        case PostTypeEnum.SUGGESTION:
          return { name: ep.name, postType: PostTypeEnum.SUGGESTION };
        case PostTypeEnum.GOSSIP:
          return { name: ep.name, postType: PostTypeEnum.GOSSIP };
        case PostTypeEnum.OFFER:
          return { name: ep.name, postType: PostTypeEnum.OFFER };
        case PostTypeEnum.SEEKING:
          return { name: ep.name, postType: PostTypeEnum.SEEKING };
        default:
          return { name: 'Nicht definiert', postType: undefined };
      }
    });
  };

  const handleChangePage = (newPage: number): void => {
    // Check if new page is the first or last page
    if (newPage === pagination.totalPages || newPage === -1) {
      return;
    }
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    setPageIndex(newPage);
  };

  const handleChangeGeoArea = (
    newSelectedGeoArea: GeoArea | undefined
  ): void => {
    setPageIndex(initiapPaginationParams.page);
    if (!newSelectedGeoArea) {
      setSelectedGeoAreas([]);
      return;
    }
    const alreadySelected = selectedGeoAreas.find(
      selectedGeoArea => selectedGeoArea.id === newSelectedGeoArea.id
    );
    let newGeoAreaList: GeoArea[] = [];
    if (alreadySelected) {
      // remove from the selectedGeoAreas list
      newGeoAreaList = selectedGeoAreas.filter(
        selectedGeoArea => selectedGeoArea.id !== newSelectedGeoArea.id
      );
    } else {
      // add to the selectedGeoAreas list
      newGeoAreaList = [...selectedGeoAreas, newSelectedGeoArea];
    }
    setSelectedGeoAreas(newGeoAreaList);
  };

  const handleChangePostType = (
    newPostType: PostTypeEnum | undefined
  ): void => {
    setPageIndex(initiapPaginationParams.page);
    if (!newPostType) {
      setSelectedPostTypes([]);
      return;
    }
    const alreadySelected = selectedPostTypes.find(
      selectedPostType => selectedPostType === newPostType
    );
    let newSelectedPostTypesList: PostTypeEnum[] = [];
    if (alreadySelected) {
      // remove from the selectedPostTypes list
      newSelectedPostTypesList = selectedPostTypes.filter(
        selectedPostType => selectedPostType !== newPostType
      );
    } else {
      // add to the selectedPostTypes list
      newSelectedPostTypesList = [...selectedPostTypes, newPostType];
    }
    setSelectedPostTypes(newSelectedPostTypesList);
  };

  const resetFilters = (): void => {
    setPageIndex(initiapPaginationParams.page);
    setSelectedGeoAreas([]);
    setSelectedPostTypes([]);
  };

  useEffect(() => {
    const getExternalPosts = async (): Promise<void> => {
      try {
        const initialPaginationParams: Pagination = {
          page: 0,
          rowsPerPage: 6,
          totalElements: 0,
          totalPages: 0
        };
        const count = initialPaginationParams.rowsPerPage;
        const page = pageIndex ?? initialPaginationParams.page;
        const geoAreaIds = getGeoAreaIds(selectedGeoAreas);
        // if geoAreaIds is empty get post from all geo areas
        const newGeoAreasIds =
          geoAreaIds.length === 0 ? getGeoAreaIds(geoAreas) : geoAreaIds;
        const postTypes = selectedPostTypes;
        const externalPostsEffect: PageOfClientPost = await grapevinePostApi().getAllExternalPostsByGeoAreasUsingGET(
          {
            count,
            page,
            postTypes,
            geoAreaIds: newGeoAreasIds,
            sortBy: GetAllExternalPostsByGeoAreasUsingGETSortByEnum.CREATED
          }
        );

        const paginationResponse: Pagination = {
          page: externalPostsEffect.number ?? initialPaginationParams.page,
          rowsPerPage: externalPostsEffect.size ?? count,
          totalElements:
            externalPostsEffect.totalElements ??
            initialPaginationParams.totalElements,
          totalPages:
            externalPostsEffect.totalPages ?? initialPaginationParams.totalPages
        };
        setPagination(paginationResponse);
        setClientPosts(externalPostsEffect.content ?? []);
      } catch (error) {
        // handle error
      }
      setLoading(false);
    };
    if (geoAreas.length > 0 && externalPosts.length > 0) {
      getExternalPosts();
      setLoading(true);
    }
  }, [geoAreas, externalPosts, pageIndex, selectedGeoAreas, selectedPostTypes]);

  useEffect(() => {
    const extPosts = getProccesedExteralPosts();
    updateAppTitle(REACT_APP_TITLE);
    setGeoAreas(geoAreasList);
    setExternalPosts(extPosts);
  }, []);

  return (
    <>
      <HeaderView title={REACT_APP_TITLE} />
      <ExternalPostsView
        loading={loading}
        externalPosts={clientPosts}
        externalPostsTypes={externalPosts}
        geoAreas={geoAreas}
        selectedGeoAreas={selectedGeoAreas}
        selectedPostTypes={selectedPostTypes}
        handleChangeGeoArea={handleChangeGeoArea}
        handleChangePostType={handleChangePostType}
        pagination={pagination}
        resetFilters={resetFilters}
        handleChangePage={handleChangePage}
      />
      <FooterView logos={logos} />
    </>
  );
}

export default ExternalPostsContainer;
