import React from 'react';
import ddCube from '../assets/dd-cube-adapted.png';
import lommatzscher from '../assets/lommatzscher_bg.png';

interface Props {
  title: string;
}
const HeaderView = (props: Props): JSX.Element => {
  const { title } = props;
  return (
    <>
      <div className="bg-light w-100 p-3 d-flex flex-wrap align-items-center justify-content-left">
        <div>
          <img className="m-3" src={ddCube} alt="Some logo" width="80px" />
        </div>
        <div>
          <h1 className="text-dark font-weight-bold ml-3 mb-0">{title}</h1>
          <span className="text-dark ml-3 header-main-subtitle">
            Aktuelle Neuigkeiten aus der Lommatzscher Pflege
          </span>
        </div>
      </div>
      <div className="divider" />
      <div className="headerImageHolder">
        <img id="headerImageHolderImg" src={lommatzscher} alt="Bild" />
        <span id="headerImageHolderSpan">
          Foto: Thomas Minkner (Fotowettbewerb LP)
        </span>
      </div>
    </>
  );
};

export default HeaderView;
