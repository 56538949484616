// TEMPORAL: REMOVE next line
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Suspense } from 'react';
import { ClientPost, ClientPostTypeEnum } from 'digitale-doerfer-api/models';
import { GetAllExternalPostsByGeoAreasUsingGETPostTypesEnum as PostTypeEnum } from 'digitale-doerfer-api/apis';
import NavigatorView from './Navigator.view';
import { Pagination } from '../models/Pagination';
import { GeoArea } from '../models/GeoArea';
import FiltersView from './Filters.view';
import { ExternalPostType } from '../models/ExternalPost';

const ExternalPostView = React.lazy(() => import('./ExternalPost.view'));

const LoadingComponent = (
  <div style={{ height: '50vh', alignItems: 'center', display: 'flex' }}>
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Wird geladen...</span>
    </div>
  </div>
);
interface Props {
  externalPosts: ClientPost[];
  externalPostsTypes: ExternalPostType[];
  loading: boolean;
  geoAreas: GeoArea[];
  pagination: Pagination;
  selectedGeoAreas: GeoArea[];
  selectedPostTypes: PostTypeEnum[];
  handleChangePostType: (newPostType: PostTypeEnum | undefined) => void;
  handleChangeGeoArea: (newGeoAreaId: GeoArea | undefined) => void;
  handleChangePage: (newPage: number) => void;
  resetFilters: () => void;
}

const ExternalPostsView = (props: Props): JSX.Element => {
  const {
    externalPosts,
    externalPostsTypes,
    geoAreas,
    loading,
    pagination,
    handleChangePage,
    handleChangePostType,
    handleChangeGeoArea,
    resetFilters,
    selectedGeoAreas,
    selectedPostTypes
  } = props;

  const noPostAvailableView = (
    <div className="jumbotron jumbotron-fluid rounded">
      <div className="container text-center">
        <h2 className="display-6">
          Es existieren keine Beiträge für deine Filter!
        </h2>
        <button
          type="button"
          className="btn btn-link btn-lg text-black-50 rounded mx-1 ml-3 mt-3"
          onClick={(): void => resetFilters()}
        >
          Filter zurücksetzen
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-white container-fluid mt-3">
      <div className="row">
        <div className="col-12 col-lg-8 col-xl-9 mb-3">
          <div className="container-fluid">
            <div className="row justify-content-center">
              {loading ? (
                LoadingComponent
              ) : (
                <Suspense fallback={LoadingComponent}>
                  {externalPosts.map(externalPost => {
                    let geoAreaName;
                    switch (externalPost.type) {
                      case ClientPostTypeEnum.HAPPENING:
                        geoAreaName =
                          geoAreas.find(
                            geoArea =>
                              geoArea.id === externalPost.happening?.geoAreaId
                          )?.name ?? '';
                        return (
                          <ExternalPostView
                            key={externalPost.id}
                            geoAreaName={geoAreaName}
                            externalPost={(externalPost.happening as any) ?? {}}
                            externalPostType={ClientPostTypeEnum.HAPPENING}
                          />
                        );
                      case ClientPostTypeEnum.NEWSITEM:
                        geoAreaName =
                          geoAreas.find(
                            geoArea =>
                              geoArea.id === externalPost.newsItem?.geoAreaId
                          )?.name ?? '';
                        return (
                          <ExternalPostView
                            key={externalPost.id}
                            geoAreaName={geoAreaName}
                            externalPost={(externalPost.newsItem as any) ?? {}}
                            externalPostType={ClientPostTypeEnum.NEWSITEM}
                          />
                        );
                      default:
                        return '';
                    }
                  })}
                </Suspense>
              )}
            </div>
          </div>
          <NavigatorView
            page={pagination.page}
            totalPages={pagination.totalPages}
            handleChangePage={handleChangePage}
          />
          {externalPosts.length === 0 && !loading ? noPostAvailableView : ''}
        </div>

        <FiltersView
          geoAreas={geoAreas}
          externalPostsTypes={externalPostsTypes}
          selectedGeoAreas={selectedGeoAreas}
          selectedPostTypes={selectedPostTypes}
          handleChangeGeoArea={handleChangeGeoArea}
          handleChangePostType={handleChangePostType}
        />
      </div>
    </div>
  );
};

export default ExternalPostsView;
