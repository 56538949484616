/* eslint-disable */

import { BaseAPI } from 'digitale-doerfer-api/runtime';
import { GrapevinePostApi } from 'digitale-doerfer-api/apis';
import DigitaleDoerferAPIFactory from './shared/utils/DigitaleDoerferAPIFactory';
import { ImageService } from './shared/services/Image.service';
import { TimeService } from './shared/services/Time.service';

interface ClassWithNoArgsConstructor<T> {
  new (): T;
}

export abstract class AbstractServiceFactory {
  private initializingServices: Set<string> = new Set<string>();

  private initializedServices: string[] = [];

  /**
   * Only for unit testing. Do not call or even rely on this data in production code.
   */
  public getInitializedServices(): string[] {
    // using slice to create a copy
    return this.initializedServices.slice();
  }

  protected lazyInit<T>(uniqueServiceName: string, init: () => T): () => T {
    let value: T;

    return (): T => {
      if (!value) {
        if (this.initializingServices.has(uniqueServiceName)) {
          throw new Error(
            `Trying to re-initialize already initialized service ${uniqueServiceName}. This is ` +
              `a possible indication for cyclic dependencies. Init trace: ${this.initializedServices}`
          );
        }
        this.initializingServices.add(uniqueServiceName);
        value = init();
        this.initializingServices.delete(uniqueServiceName);
        this.initializedServices.push(uniqueServiceName);
      }
      return value;
    };
  }

  protected lazyInitSimpleService<T>(
    uniqueServiceName: string,
    Service: ClassWithNoArgsConstructor<T>
  ): () => T {
    return this.lazyInit(uniqueServiceName, () => new Service());
  }

  protected lazyInitApiWithMiddleWare<T extends BaseAPI>(
    uniqueServiceName: string,
    Api: ClassWithNoArgsConstructor<T>
  ): () => T {
    return this.lazyInit(uniqueServiceName, () =>
      DigitaleDoerferAPIFactory.createDigitaleDoerferAPI(new Api())
    );
  }
}

/**
 * Main service factory. This class is exported only for unit testing. Never use or instantiate manually in production code.
 *
 * Unfortunately, during production build, the name attributes of classes are minified and become ambiguous. Thus, we have
 * to specify a unique name for every service. Currently, this is just the service name itself. You should also name the
 * attribute exactly the same (only starting with a small letter). This is tested in ServiceFactory.spec.ts. So if this
 * test fails, you either introduced a cyclic dependency or you misspelled a service name.
 */
export class MainServiceFactory extends AbstractServiceFactory {
  // API Services
  grapevinePostApi = this.lazyInitApiWithMiddleWare<GrapevinePostApi>(
    'GrapevinePostApi',
    GrapevinePostApi
  );

  // Business Services
  imageService = this.lazyInit('ImageService', () => new ImageService());

  timeService = this.lazyInit('TimeService', () => new TimeService());
}

const mainSeviceFactory = new MainServiceFactory();

// API Services
export const { grapevinePostApi } = mainSeviceFactory;

// Business Services
export const { imageService } = mainSeviceFactory;
export const { timeService } = mainSeviceFactory;
