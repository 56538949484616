/* eslint-disable */

import React from 'react';
import clsx from 'clsx';
import { GetAllExternalPostsByGeoAreasUsingGETPostTypesEnum as PostTypeEnum } from 'digitale-doerfer-api/apis';
import { GeoArea } from '../models/GeoArea';
import { ExternalPostType } from '../models/ExternalPost';
import { HIDE_GEO_AREA_FILTER } from '../shared/utils/Settings';

interface Props {
  selectedGeoAreas: GeoArea[];
  selectedPostTypes: PostTypeEnum[];
  geoAreas: GeoArea[];
  externalPostsTypes: ExternalPostType[];
  handleChangePostType: (newPostType: PostTypeEnum | undefined) => void;
  handleChangeGeoArea: (newGeoAreaId: GeoArea | undefined) => void;
}
const FiltersView = (props: Props): JSX.Element => {
  const {
    geoAreas,
    externalPostsTypes,
    selectedGeoAreas,
    selectedPostTypes,
    handleChangeGeoArea,
    handleChangePostType
  } = props;

  /**
   *  Highlight button if selected.
   * @param isSelected true if button is selected, otherwise, false.
   */
  const selectedButton = (isSelected?: boolean): string => {
    return isSelected
      ? clsx('badge', 'badge-dark', 'py-2')
      : clsx('d-inline-block', ' p-2');
  };

  return (
    <>
      <div className="col-12 col-lg-4 col-xl-3 mb-3 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="bg-light filter-container">
                <h3 className="h3 pt-3 pr-3 pl-3 pb-2 mb-0">Filter</h3>
                <div className="divider-small" />
                <div className="pt-3 pr-3 pl-3 pb-2">
                  <h5 className="d-inline align-middle filter-subtitle">
                    Art der Beiträge
                  </h5>
                </div>
                <div className="ml-3">
                  {externalPostsTypes.map(ep => {
                    const isSelected = !!selectedPostTypes.find(
                      selectedPostType => selectedPostType === ep.postType
                    );
                    return (
                      <React.Fragment key={`${ep.name}-${ep.postType}`}>
                        <button
                          type="button"
                          className="btn btn-link filter-label"
                          onClick={(): void =>
                            handleChangePostType(ep.postType)
                          }
                        >
                          <span className={selectedButton(isSelected)}>
                            {ep.name}
                          </span>
                        </button>
                        <br />
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="pb-3">
                  {selectedPostTypes.length !== 0 ? (
                    <span
                      className="btn btn-link d-inline mx-1 align-middle back-btn"
                      onClick={(): void => handleChangePostType(undefined)}
                    >
                      Zurücksetzen
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                {!HIDE_GEO_AREA_FILTER && (
                  <>
                    <div className="mb-2">
                      <h5 className="d-inline align-middle">
                        Orte der Beiträge
                      </h5>
                      {selectedGeoAreas.length !== 0 ? (
                        <span
                          className="btn btn-link d-inline text-black-50 mx-1 align-middle"
                          onClick={(): void => handleChangeGeoArea(undefined)}
                        >
                          Zurücksetzen
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="ml-3">
                      {geoAreas.map(geoArea => {
                        const isSelected = !!selectedGeoAreas.find(
                          selectedGA => selectedGA.id === geoArea.id
                        );
                        return (
                          <React.Fragment key={geoArea.id}>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={(): void => handleChangeGeoArea(geoArea)}
                            >
                              <span className={selectedButton(isSelected)}>
                                {geoArea.name}
                              </span>
                            </button>
                            <br />
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltersView;
