import React from 'react';
import { Logo } from '../models/Logo';

interface Props {
  logos: Logo[];
}

const FooterView = (props: Props): JSX.Element => {
  const { logos } = props;
  const styles = {
    maxHeight: '50px',
    margin: '2rem' /* stylelint-disable-line */
  };

  return (
    <>
      <footer>
        <div className="bg-light w-100 p-3 d-flex flex-column flex-sm-row">
          <a
            className="text-dark my-1 mx-3"
            href="https://www.digitale-doerfer-sachsen.de/"
          >
            Über uns
          </a>
          <a
            className="text-dark my-1 mx-3"
            href="https://www.digitale-doerfer-sachsen.de/kontakt/"
          >
            Kontakt
          </a>
          <a
            className="text-dark my-1 mx-3"
            href="https://www.digitale-doerfer-sachsen.de/impressum/"
          >
            Impressum
          </a>
          <a
            className="text-dark my-1 mx-3"
            href="https://www.digitale-doerfer-sachsen.de/datenschutzinformation/"
          >
            Datenschutzerklärung
          </a>
        </div>
        <div className="container-fluid p-0 bg-light">
          {logos.map(logo => {
            return (
              <a key={logo.webPageURL} href={logo.webPageURL}>
                <img style={styles} src={logo.logoSource} alt="" />
              </a>
            );
          })}
        </div>
      </footer>
    </>
  );
};

export default FooterView;
