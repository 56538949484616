/* eslint-disable */

import { ClientMediaItem } from 'digitale-doerfer-api/models';

export enum ImageSizes {
  THUMBNAIL = 0,
  EXTRASMALL = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
  ORIGINAL = -1 // default
}

export type AvatarSize =
  | ImageSizes.THUMBNAIL
  | ImageSizes.SMALL
  | ImageSizes.MEDIUM;

export interface ImageUrls {
  thumbnail: string;
  extraSmall: string;
  small: string;
  medium: string;
  large: string;
  original: string;
}

export interface AlbumEntry {
  src: string;
  caption: string;
  thumb: string;
}

export class ImageService {
  static DEFAULT_AVATAR_PICTURE = 'assets/avatar_default.png';

  getAvatarPicture(mediaItem: ClientMediaItem, size: ImageSizes): string {
    return mediaItem
      ? this.getAvailableImageUrl(mediaItem, size)
      : ImageService.DEFAULT_AVATAR_PICTURE;
  }

  getCSSClass(size: AvatarSize): string {
    switch (size) {
      case ImageSizes.THUMBNAIL: {
        return 'avatar-xs';
      }
      case ImageSizes.SMALL: {
        return 'avatar-sm';
      }
      case ImageSizes.MEDIUM: {
        return 'avatar-md';
      }
    }
  }

  /**
   * If the desidered size is not available, tries the next one. If the current
   * size is not available, returns the original
   */
  getAvailableImageUrl(mediaItem: ClientMediaItem, size: ImageSizes): string {
    let imageUrl = '';
    if (mediaItem) {
      const imageUrls = this.getImageUrls(mediaItem);
      if (imageUrls && Object.keys(imageUrls).length !== 0) {
        const imageUrlsKeys = Object.keys(imageUrls);
        const imagesSizesOfMediaItem = imageUrlsKeys
          .map(
            (imageUrlKey: string) =>
              ImageSizes[imageUrlKey.toUpperCase() as keyof typeof ImageSizes]
          )
          .sort()
          .reverse();
        const bestImageSize: ImageSizes = imagesSizesOfMediaItem.find(
          imageSize => imageSize <= size
        ) as ImageSizes;
        imageUrl = this.getImageUrlFromImageSize(imageUrls, bestImageSize);
      }
    }
    return imageUrl;
  }

  /**
   * Prepare Album for LightBox
   * @param images :  ClientMediaItem[]
   */
  prepareAlbum(images: ClientMediaItem[], msg: string): AlbumEntry[] {
    const album = [];
    for (const img of images) {
      const entry = {
        src: this.getAvailableImageUrl(img, ImageSizes.ORIGINAL),
        caption: msg,
        thumb: this.getAvailableImageUrl(img, ImageSizes.THUMBNAIL)
      };
      album.push(entry);
    }
    return album;
  }

  private getImageUrls(mediaItem: ClientMediaItem): ImageUrls {
    let urls = {} as ImageUrls;
    if (mediaItem) {
      const urlsAsString = mediaItem.urls;
      urls = JSON.parse(JSON.stringify(urlsAsString));
    }
    return urls;
  }

  /**
   * Return image URL based on the imageSize given by parameter.
   */
  private getImageUrlFromImageSize(
    imagesUrls: ImageUrls,
    imageSize: number
  ): string {
    let imageUrl;
    // Special conditional needed due to capitalize 'S' of 'extraSmall' :)
    if (ImageSizes[imageSize] === 'EXTRASMALL') {
      imageUrl = imagesUrls.extraSmall;
    } else {
      imageUrl =
        imagesUrls[ImageSizes[imageSize].toLowerCase() as keyof ImageUrls];
    }
    return imageUrl;
  }
}
